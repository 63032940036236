import { PAGE_URLS } from '~/config/page-url'
import { PAGE_PARAMS } from '~/constants/common'

export const FORM_AUTH = {
  LABEL_USERNAME: 'Tên đăng nhập',
  PLACEHOLDER_USERNAME: 'Nhập tên đăng nhập',
  VALIDATE_USERNAME: 'Tên đăng nhập không hợp lệ',
  VALIDATE_PHONE: 'Số điện thoại không hợp lệ',
  LABEL_PASSWORD: 'Mật khẩu',
  PLACEHOLDER_PASSWORD: 'Nhập mật khẩu',
  LABEL_REMEMBER: 'Lưu tài khoản',
  FORGOT_PASSWORD: 'Quên mật khẩu',
  LOGIN: 'Đăng nhập',
  REGISTER: 'Đăng ký',
  LOGIN_NOW: 'Đăng nhập ngay',
  REGISTER_NOW: 'Đăng ký ngay',
  LOGIN_SUGGEST: 'Bạn đã có tài khoản!',
  REGISTER_SUGGEST: 'Bạn chưa có tài khoản!',
  RESET_PASSWORD: 'Đổi mật khẩu',
  LABEL_CHECKBOX: `Tôi xác nhận rằng tôi đã trên 18 tuổi và đã đồng ý với các <a href=${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.POLICY} class='policy' target="_blank">Điều kiện & Điều khoản</a> tham gia của trang web.`,
  LABEL_PHONE_NUMBER: 'Số điện thoại',
  PLACEHOLDER_PHONE_NUMBER: 'Nhập số điện thoại',
  TEXT_EXIST: 'Tên đăng nhập đã tồn tại',
  PLACEHOLDER_FORGOT_PASSWORD: 'Nhập địa chỉ Email',
  LABEL_FORGOT_PASSWORD: 'Email',
  DESC_FORGOT_PASSWORD: 'Nếu bạn gặp bất kỳ vấn đề gì khi đăng nhập, vui lòng liên hệ CSKH 24/7 để được hỗ trợ.',
  LABEL_RESET_PASSWORD: 'Mật khẩu mới',
  LABEL_RESET_CONFIRM_PASSWORD: 'Nhập lại mật khẩu mới'
}
